<template>
  <v-container>
    <!-- <v-row no-gutters class="flex-nowrap"></v-row> -->
    <v-row>
      <v-col
        cols="12"
        class="dashboard pa-0 ma-0"
      >
        <img
          class="dashboard__logo"
          src="./../../assets/img/global/repairs/e_r_logo_white.png"
          alt=""
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {}
</script>
